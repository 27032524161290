<template>
  <Layout>
    <PageHeader :items="items"/>
    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getAllCities()"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Company List-->
          <!--          <div-->
          <!--            v-if="-->
          <!--              this.$storageData.profile.empTypeID == 1 ||-->
          <!--                this.$storageData.profile.empTypeID == 11-->
          <!--            "-->
          <!--            class="col-md-2"-->
          <!--            style="width: 16%"-->
          <!--          >-->
          <!--            <label>Company</label>-->
          <!--            <multiselect-->
          <!--              v-model="companyID"-->
          <!--              :options="companyArr"-->
          <!--              :show-labels="false"-->
          <!--              label="companyName"-->
          <!--              track-by="companyID"-->
          <!--              @input="getRestaurantBrandsByCompanyID()"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div class="col-md-2">
            <label>Select City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="getPrincipleAndCorporates()"
            ></multiselect>
          </div>
          <div v-if="login_type != 3" class="col-md-4">
            <label>Select Principle Branch </label>
            <multiselect
                v-model="principleBranchID"
                :options="principleBranches"
                :show-labels="false"
                label="principleBranchName"
                track-by="principleBranchID"
                @input="getCorporateBranchByPrinciple(),getRestaurantByPrincipleBranch()"
                @remove="getCorporateBranches"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <label class="form-label">Select Corporate Branch </label>
            <multiselect
                v-model="corpBranchID"
                :options="corpBranches"
                :show-labels="false"
                label="corpBranchName"
                track-by="corpBranchID"
                @input="getRestaurantByCorporateBranch"
            ></multiselect>
            <!-- @input="onchangeBranch();" -->
          </div>
        </div>
        <div class="row mt-3">
          <!--          <div class="col-md-3">-->
          <!--            <label>Restaurant Brand</label>-->
          <!--            <multiselect-->
          <!--              v-model="brandData"-->
          <!--              :options="brandArr"-->
          <!--              :show-labels="false"-->
          <!--              label="name"-->
          <!--              track-by="restID"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!--           Brand List END-->
          <!-- Cities List-->
          <!--          <div-->
          <!--            v-if="-->
          <!--              this.$storageData.profile.empTypeID == 1 ||-->
          <!--                this.$storageData.profile.empTypeID == 11-->
          <!--            "-->
          <!--            class="col-md-2"-->
          <!--            style="width: 12%"-->
          <!--          >-->
          <!--            <label>City </label>-->
          <!--            <multiselect-->
          <!--              v-model="city"-->
          <!--              :options="cityArr"-->
          <!--              :show-labels="false"-->
          <!--              label="city"-->
          <!--              track-by="city"-->
          <!--              @input="readRestaurantBranchData()"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!--END Cities List-->
          <!-- Branch List-->
          <!--          <div-->
          <!--            v-if="-->
          <!--              this.$storageData.profile.empTypeID == 1 ||-->
          <!--                this.$storageData.profile.empTypeID == 11-->
          <!--            "-->
          <!--            class="col-md-2"-->
          <!--            style="width: 16%"-->
          <!--          >-->
          <!--            <label>Restaurant Branch </label>-->
          <!--            <multiselect-->
          <!--              v-model="branchID"-->
          <!--              :options="branches"-->
          <!--              :show-labels="false"-->
          <!--              label="branchName"-->
          <!--              track-by="restBranchID"-->
          <!--              @input="onchangeBranch()"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!-- Branch List END-->
          <!-- Date range -->
          <!--          <div class="col-md-3">-->
          <!--            <label class="form-label" for="form row-endTime-input"-->
          <!--              >Select Restaurant Brand</label-->
          <!--            >-->
          <!--            <multiselect-->
          <!--              v-model="restBranchID"-->
          <!--              :class="{-->
          <!--                'is-invalid': submitted && $v.restBranchID.$error-->
          <!--              }"-->
          <!--              :options="restBranchArr"-->
          <!--              label="restaurantName"-->
          <!--              track-by="restBranchID"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <div class="col-md-3">
            <label class="form-label" for="form row-endTime-input"
            >Select Restaurant Branch</label
            >
            <multiselect
                v-model="restBranchID"
                :class="{
                'is-invalid': submitted && $v.restBranchID.$error
              }"
                :options="restBranchArr"
                label="restaurantName"
                track-by="restBranchID"
            ></multiselect>
          </div>
          <!--          <div class="col-md-3">-->
          <!--            <label class="form-label" for="form row-endTime-input"-->
          <!--              >Select Day of Week</label-->
          <!--            >-->
          <!--            <multiselect-->
          <!--              v-model="day"-->
          <!--              :options="daysOfWeek"-->
          <!--              label="key"-->
          <!--              track-by="val"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!--          <div class="col-md-3">-->
          <!--            <label class="form-label" for="form row-endTime-input"-->
          <!--              >Select Session</label-->
          <!--            >-->
          <!--            <multiselect v-model="session" :options="sessions"></multiselect>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--        <div class="row mt-3">-->
          <div class="col-md-3">
            <label>Date Range </label>
            <date-picker
                v-model="daterange"
                append-to-body
                confirm
                format="DD MMM Y"
                value-type="format"
                lang="en"
                range
                :disabled-date="dateBefore"
            ></date-picker>
          </div>
          <div class="col-md-2" style="width: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 16%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <div class="col-md-2" >
            <button
                class="btn btn-themeYellow float-end mt-4"
                :disabled="generating"
                @click="refreshDsr()"
            >
              <i class="uil-refresh"></i> Generate DSR
            </button>
            <div v-if="generating"
                 class="text-center text-danger float-end"
            >
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row mb-2">
                <div class="col-md-2" style="width: auto">
                  <button id="download-csv" class="btn btn-themeOrange mt-4" @click="downlaodDataAsCSV"><i class="fas fa-file-csv"></i> Download CSV
                  </button>
                </div>
                <div class="col-md-2" style="width: auto">
                  <button id="download-xlsx" class="btn btn-themeOrange mt-4" @click="downlaodDataAsXLSX"><i class="fas fa-file-excel"></i> Download XLSX
                  </button>
                </div>
<!--                <div class="col-md-2" style="width: auto">-->
<!--                  <button id="download-pdf" class="btn btn-themeOrange mt-4" @click="downlaodDataAsPDF"><i class="far fa-file-pdf"></i> Download PDF-->
<!--                  </button>-->
<!--                </div>-->
              </div>
              <div ref="dsrTable">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<script>
// import Vue from 'vue';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import {required} from "vuelidate/lib/validators";
// import VueTabulator from 'vue-tabulator';
// const Tabulator = require('tabulator-tables');
// Vue.use(VueTabulator, {
//   name: 'AwesomeTable'
// });
import Tabulator from 'tabulator-tables';
import * as XLSX from 'xlsx/xlsx.mjs';
window.XLSX = XLSX;
import { jsPDF } from "jspdf";
window.jsPDF = jsPDF;
 import autoTable from 'jspdf-autotable'
window.autoTable = autoTable;
export default {
  page: {
    title: "Daily Sales Report",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker

  },
  data() {
    return {
      tabulator: null,
      login_type: 0,
      tableData: [],
      tableExcelData: [],
      tableData2: [],
      title: "Daily Sales Report",
      items: [
        {
          text: "View"
        },
        {
          text: "Daily Sales Report",
          active: true
        }
      ],
      excelFields: {},
      restaurants: [],
      branches: [],
      cityArr: [],
      city: "",
      topSellingProducts: [],
      daterange: [],
      countryArr: [],
      orderData: [],
      orderDetails: [],
      country: "",
      brandArr: [],
      brandData: "",
      restBranchID: this.$storageData.profile.restBranchID,
      reviewText: "",
      tabflag: "",
      isReportTableBusy: false,
      generating:false,
      replyField: "",
      feedbackIdhold: "",
      repliedTextArr: [],
      otherRatingsArr: [],
      ratingbyuser: "",
      series: [],
      repliedText: "",
      repliedBy: "",
      submitted: false,
      restaurantID: "",
      branchID: "",
      companyArr: [],
      companyID: 0,
      loadingtable: false,
      ratingQ: 5,
      restNAme: "",
      sortBy: "age",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      allreliesreplier: [],
      sortDesc: false,
      reviewtxt: "",
      reviewed_questions: [],

      // ////////// datatable 3 category //////////////////

      // datatable 1 & 2 ///////
      sortBy2: "age",
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter2: null,
      filterOn2: [],
      sortDesc2: false,

      principleBranchID: "",
      principleBranches: [],
      corpBranchID: "",
      corpBranches: [],
      restBranchArr: [],
      isEdit: false,
      total_ratings: 0,
      average_ratings: 0,
      total_reviews: 0,
      ratings_equal_above_three: 0,
      ratings_below_three: 0,
      ratings_by_male: 0,
      ratings_by_female: 0,
      ratings_by_others: 0,
      chart_date_wise_ratings: [],
      chart_date_wise_average_ratings: [],
      chart_day_wise_ratings: [],
      chart_day_wise_average_ratings: [],
      chart_session_wise_ratings: [],
      chart_session_wise_average_ratings: []
    };
  },
  validations: {
    replyField: {
      required
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    rows2() {
      return this.tableData2.length;
    }
  },
  watch: {
    //update table if data changes
    tableData: {
      handler: function (newData) {
        this.tabulator.replaceData(newData);
      },
      deep: true,
    }
  },
  mounted() {
    this.login_type = this.$storageData.login_type;
    // Set the initial number of items
    this.totalRows = this.items.length;
    if (this.$storageData.profile.empTypeID != 2 || this.$storageData.profile.empTypeID != 11) {
      this.getAllCountry();
    } else if (
        this.$storageData.profile.empTypeID == 2 ||
        this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    }
    this.tabulator = new Tabulator(this.$refs.dsrTable, {
      data: this.tableData, //link data to table
      height:"830px",
      reactiveData: true,
       pagination:"local",
      resizableColumnFit:true,
      paginationSize:15,
      paginationSizeSelector:true,
      columns: [
        {title: "City", field: "city"},
        {title: "Principle Branch", field: "principle_branch"},
        {title: "Corporate Branch", field: "corporate_branch"},
        {title: "Restaurant Brand", field: "restaurant"},
        {title: "Restaurant Branch", field: "restaurant_branch"},
        {title: "Date", field: "date"},
        {title: "Total Transaction Count", field: "total_transactions"},
        {title: "Total Sale Value", field: "total_sales"},
        {title: "Average Check", field: "average"},
        {title: "Meal Plan Transactions", field: "meal_plan_transactions"},
        {title: "Meal Plan Revenue", field: "meal_plan_revenue"},
        {title: "Meal Plan AOV", field: "meal_plan_aov"},
        {title: "Cash & Carry Transactions", field: "cash_and_carry_transactions"},
        {title: "Cash & Carry Revenue", field: "cash_and_carry_revenue"},
        {title: "Cash & Carry AOV", field: "cash_and_carry_aov"},
        {title: "Cash Transactions", field: "cash_transactions"},
        {title: "Cash Revenue", field: "cash_revenue"},
        {title: "QR Transactions", field: "qr_transactions"},
        {title: "QR Revenue", field: "qr_revenue"},
        {title: "App Transactions", field: "app_transactions"},
        {title: "App Revenue", field: "app_revenue"},
        {title: "CCAvenue Transactions", field: "ccavenue_transactions"},
        {title: "CCAvenue Revenue", field: "ccavenue_revenue"},
        {title: "PayU Transactions", field: "payu_transactions"},
        {title: "PayU Revenue", field: "payu_revenue"},
        {title: "POS Transactions", field: "pos_transactions"},
        {title: "POS Revenue", field: "pos_revenue"},
        {title: "DBQR Transactions", field: "dbqr_transactions"},
        {title: "DBQR Revenue", field: "dbqr_revenue"},
        {title: "SW APP Transactions", field: "sw_app_transactions"},
        {title: "SW App Revenue", field: "sw_app_revenue"},
        {title: "SW Razorpay Revenue", field: "razorpay_revenue"},
        {title: "SW Paytm DQR", field: "paytmdqr_revenue"},
        {title: "SW Wallet Points", field: "points_wallet"},
        {title: "Sodexo Transactions", field: "sodexo_transactions"},
        {title: "Sodexo Sales", field: "sodexo_revenue"},
        {title: "Wallet Transactions", field: "wallet_transactions"},
        {title: "Wallet Points", field: "wallet_points"},
        {title: "Cafeteria Transactions", field: "total_cafeteria_transactions"},
        {title: "Cafeteria Sales", field: "total_cafeteria_sales"},
        {title: "Event Sales Revenue", field: "event_sales_revenue"},
        {title: "Event Sales Tax", field: "event_sales_tax"},
      ]
    });
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  methods: {
    dateBefore(date){
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return date > today;

    },
    downlaodDataAsCSV(){
    this.tabulator.download("csv","dsr.csv")
    },
    downlaodDataAsXLSX(){
      this.tabulator.download("xlsx", "dsr.xlsx",{sheetName:"Daily Sales Report"});
    },
    downlaodDataAsPDF(){
      this.tabulator.download("pdf","dsr.pdf",{
        orientation:"landscape",
        title:"Daily Sales Report", //add title to report
      })
    },

     onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshDsr(){
      if (this.daterange.includes(null) || this.daterange.length==0)
      {
        this.$swal({
          icon:'error',
          title:'Generate DSR',
          text:'Please select date first!',
        });
      }
      else{
        this.generating=true;
        this.axios
            .post(this.$loggedRole+"/dsr/refresh-dsr",{
              dateFilter: this.daterange,
            })
            .then((response) => {
              this.generating=false;
              this.$swal({
                icon:'success',
                title:'Generate DSR',
                text:response.data.message,
              });
            })
            .catch((error)=>{
              this.isTableBusy = false;
              this.isLoading = 0;
              this.generating=false;
              this.$swal({
                icon:"error",
                text:error.response.data.message,
              });
            });
      }
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole + "/getAllCountry").then(response => {
        this.countryArr = response.data.data;
        if (this.$storageData.profile.empTypeID == 11) {
          // food company login
          this.getAllCompanies();
          this.getRestaurantBrandsByCompanyID();
        } else {
          // this.readRestaurantBranchData();
        }
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      let corporate_branch = 0;
      let corporate_id = 0;
      if (this.$storageData.profile.empTypeID == 5) {
        corporate_id = this.$storageData.profile.corporateID;
      }
      if (this.$storageData.profile.empTypeID == 6) {
        corporate_id = this.$storageData.profile.corporateID;
        corporate_branch = this.$storageData.profile.corpBranchID;
      }
      let formData = new FormData();
      formData.append("country", this.country ? this.country.country : "");
      formData.append("corporate_id", corporate_id);
      formData.append("corporate_branch", corporate_branch);
      formData.append("empTypeID", this.$storageData.profile.empTypeID);
      formData.append("empID", this.$storageData.profile.pid);
      this.axios.post(this.$loggedRole + "/v2/getAllCities", formData).then(response => {
        this.cityArr = response.data.data;
        //this.city = (this.cityArr) ? this.cityArr[0] : "";
        if (this.$storageData.profile.empTypeID == 1) {
          // super admin
          // this.getRestaurantBrands();
          this.getAllCompanies();
        }
        if (this.$storageData.profile.empTypeID == 11) {
          // food company login
          this.getAllCompanies();
          this.getRestaurantBrandsByCompanyID();
        } else {
          this.readRestaurantBranchData();
        }
      });
    },

    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    getRestaurantBrands() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantList", {
            restID: this.$storageData.profile.restID,
            restBranchID: this.$storageData.profile.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID
          })
          .then(result => {
            this.brandArr = result.data.data;
            this.brandData = this.brandArr ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
          });
    },
    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
          .post(this.$loggedRole + "/getRestaurantBrandsByCompanyID", {companyID: companyID})
          .then(response => {
            this.brandArr = response.data.data;
            // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
          });
    },
    getRestaurantCountry() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantCountry", {restID: this.$storageData.profile.restID})
          .then(response => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getAllCompanies();
            this.getRestaurantCities();
          });
    },
    getRestaurantCities() {
      var restID = this.brandData.restID;
      if (this.$storageData.profile.empTypeID == 11) {
        restID = this.$storageData.profile.restID;
      }
      this.axios
          .post(this.$loggedRole + "/getRestaurantCities", {
            restID: restID,
            country: this.country ? this.country.country : ""
          })
          .then(response => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
          .post(this.$loggedRole + "/getAllCompanies", {companyID: companyID})
          .then(response => {
            this.companyArr = response.data.data;
            if (this.$storageData.profile.empTypeID == 11) {
              this.companyID = this.companyArr ? this.companyArr[0] : "";
            }
          });
    },
    readRestaurantBranchData() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantBranchByRestID", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID: 0,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : ""
          })
          .then(response => {
            this.branches = response.data.data;
            this.branchID = this.branches ? this.branches[0] : "";
            this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
            //this.getProductMixReport();
          });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();

    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      //this.getProductMixReport();
    },

    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.getDailySalesReport();

    },

    clearFilter() {
      this.daterange = [];
      this.branches = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.paymentType = "";
      this.orderType = "";
      this.principleBranches = this.corpBranches = this.tableData= [];
      this.session = this.principleBranchID = this.corpBranchID = "";
    },

    getPrincipleAndCorporates() {
      if (this.$storageData.login_type != 3) {
        this.getPrincipleBranches();
      }
      this.getCorporateBranches();
    },
    getPrincipleBranches() {
       if(this.city){
         this.axios
             .post(this.$loggedRole + "/getPrincipleBranchByPrincipleID", {
               city: this.city ? this.city.city : "",
               empTypeID: this.$storageData.profile.empTypeID,
               empID: this.$storageData.profile.principleAdminID,
               isDashboard: 1, // for all dropdown in only dashboards
               loginTypeID: this.$storageData.login_type
             })
             .then(response => {
               this.principleBranches = response.data.data;
             })
             .catch(error => {
               this.$swal({
                 icon: "error",
                 title: "Oops...",
                 text: error.response.data.message
               });
             });
       }

    },
    getCorporateBranches() {
      let corporate_branch = 0;
      let corporate_id = 0;
      if (this.$storageData.profile.empTypeID == 5) {
        corporate_id = this.$storageData.profile.corporateID;
      }
      if (this.$storageData.profile.empTypeID == 6) {
        corporate_id = this.$storageData.profile.corporateID;
        corporate_branch = this.$storageData.profile.corpBranchID;
      }
      if(this.city){
        this.axios
            .post(this.$loggedRole + "/dsr/get-corporate-branches", {
              city: this.city ? this.city.city : "",
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.pid,
              isDashboard: 1, // for all dropdown in only dashboards
              loginTypeID: this.$storageData.login_type,
              corporate_branch: corporate_branch,
              corporate_id: corporate_id
            })
            .then(response => {
              this.corpBranches = response.data.data;
            })
            .catch(error => {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message
              });
            });
      }

    },
    getCorporateBranchByPrinciple(){
      if(this.principleBranchID){
        this.axios
            .post(this.$loggedRole + "/dsr/principle/corporate-branches", {
              city: this.city ? this.city.city : "",
              principle_branch: this.principleBranchID
                  ? this.principleBranchID.principleBranchID
                  : "",
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.principleAdminID,
              loginTypeID: this.$storageData.login_type
            })
            .then(response => {
              if(response.data.status==200){
                this.corpBranches = response.data.data;
              }
            })
            .catch(error => {
              if(error.response.status!=404){
                this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.message
                });
              }
            });
      }



    },
    getRestaurantByPrincipleBranch() {
      this.axios
          .post(this.$loggedRole + "/dsr/principle/restaurants", {
            city: this.city ? this.city.city : "",
            principleBranchID: this.principleBranchID
                ? this.principleBranchID.principleBranchID
                : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.principleAdminID,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.restBranchArr = response.data.data;
          })
          .catch(error => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
          });
    },
    getRestaurantByCorporateBranch() {
      if (this.corpBranchID) {
        this.axios
            .post(this.$loggedRole + "/dsr/corporate/restaurants", {
              city: this.city ? this.city.city : "",
              corpBranchID: this.corpBranchID
                  ? this.corpBranchID.corpBranchID
                  : "",
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.pid,
              loginTypeID: this.$storageData.login_type
            })
            .then(response => {
              this.restBranchArr = response.data.data;
            })
            .catch(error => {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message
              });
            });
      }
    },
    getDailySalesReport() {
        this.axios.post(this.$loggedRole + "/dsr/getDsrData", {
          country:this.country ? this.country.country : "",
          city:this.city ? this.city.city : "undefined",
          restBranchId: this.restBranchID
              ? this.restBranchID.restBranchID
              : 0,
          daterange: this.daterange,
          principle_branch_id: this.principleBranchID
              ? this.principleBranchID.principleBranchID
              : 0,
          corporate_branch_id: this.corpBranchID
              ? this.corpBranchID.corpBranchID
              : 0,
        }).then((response) => {
          this.tableData = response.data.data;
        }).catch((error)=>{
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message
          });
        });

    }
  },
  middleware: "authentication"
};
</script>

<style scoped>
@import "~tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css";
/** or use this class: table thead th.bTableThStyle { ... } */

.icon_align {
  margin-left: 15px;
}

.headerDiv {
  margin-bottom: 15px;
}

.setionTitle {
  border-bottom: none;
  text-align: center;
  color: #663333;
  font-size: 22px;
  margin-bottom: 10px;
}

.deliverCard {
  max-height: 95px;
  padding: 7px 8px;
  background: rgb(254, 241, 240);
}

.deliverCard figure {
  float: left !important;
  margin-right: 11px !important;
}

.restaurantCard figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  /* position: absolute; */
  margin: 0;
  /* left: 20px;
      top: 12px; */
  border: 1px solid #fff;
  border-radius: 3px;
}

.deliveryCardDiv {
  margin-top: 2px;
}

.deliverH3 {
  font-size: 14px;
  color: #663333;
  margin-bottom: 10px !important;
}

.questTitle {
  font-size: 14px;
  color: #663333;
  margin-bottom: 13px;
}

.submit-button {
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.submit-button a {
  width: 302px !important;
}

.btn-danger {
  line-height: 1;
}

.btn-warning {
  line-height: 1;
}

/* .modal-header{
  border-bottom:1px solid #ffffff;
} */
</style>
